@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

:root {
  --bg-colour: #171717;
  --font-colour: #ededed;
  --primary-colour: #444444;
  --secondary-colour: #da0037;
  --tertiary-colour: #292922;
  --border: rgba(255, 255, 255, 0.125);
  --bg-alpha: rgba(23, 23, 23, 0.75);
  --bg-alpha1: rgba(23, 23, 23, 0.1);
  --bg-alpha2: rgba(23, 23, 23, 1);
}

@keyframes matchEntrance {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* background: url("./images/Faruq2.0.2.png");
  background-position: 20% bottom;
  background-size: auto;
  background-repeat: no-repeat; */
  background-color: var(--bg-colour);
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
}

.container {
  /* background: linear-gradient(var(--bg-alpha1), var(--bg-alpha2)),
    url("https://battlebotsupdate.com/wp-content/uploads/2022/03/bbs11e14_nut.jpg"); */
  background: url("https://i.imgur.com/xmS3QgL.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.header {
  font-family: "Bebas Neue", cursive;
  font-size: x-large;
  color: var(--font-colour);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 2px solid var(--border);
  margin-bottom: 20px;
}

/* grid-container */
.content {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding-bottom: 20px;
  column-gap: 10px;
}

.col1 {
  display: grid;
  align-items: center;
  row-gap: 10px;
  grid-template-rows: repeat(8, 1fr);
}
.col2 {
  display: grid;
  align-items: center;

  grid-template-rows: repeat(16, 0.5fr);
}
.col3 {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(8, 1fr);
}

.col4 {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(8, 1fr);
}

.col5 {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(8, 1fr);
  /* background-image: url("./images/nut.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center top; */
}

.col6 {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(8, 1fr);
}
.col7 {
  display: grid;
  align-items: center;
  grid-template-rows: repeat(8, 1fr);
  /* background-image: url("./images/ray.png");
  background-repeat: no-repeat;
  background-size: contain; */
}
.col8 {
  display: grid;
  align-items: center;

  grid-template-rows: repeat(16, 0.5fr);
}
.col9 {
  display: grid;
  align-items: center;
  row-gap: 10px;
  grid-template-rows: repeat(8, 1fr);
}

/* Column1 */
.col1 > div:nth-child(1) {
  grid-row: 1;
}
.col1 > div:nth-child(2) {
  grid-row: 2;
}
.col1 > div:nth-child(3) {
  grid-row: 3;
}
.col1 > div:nth-child(4) {
  grid-row: 4;
}
.col1 > div:nth-child(5) {
  grid-row: 5;
}
.col1 > div:nth-child(6) {
  grid-row: 6;
}
.col1 > div:nth-child(7) {
  grid-row: 7;
}
.col1 > div:nth-child(8) {
  grid-row: 8;
}

/* Column2 */
.col2 > div:nth-child(1) {
  grid-row: 1 / 5;
}
.col2 > div:nth-child(2) {
  grid-row: 5 / 9;
}
.col2 > div:nth-child(3) {
  grid-row: 9 / 13;
}
.col2 > div:nth-child(4) {
  grid-row: 13 / 17;
}

/* Column3 */
.col3 > div:nth-child(1) {
  grid-row: 1 / 5;
}
.col3 > div:nth-child(2) {
  grid-row: 5 / 9;
}

/* Column4 */
.col4 > div:nth-child(1) {
  grid-row: 4 / 6;
}
/* Column5 */
.col5 > div:nth-child(1) {
  grid-row: 3;
}
.col5 > div:nth-child(2) {
  grid-row: 6;
}
/* Column6 */
.col6 > div:nth-child(1) {
  grid-row: 4 / 6;
}

/* Column7 */
.col7 > div:nth-child(1) {
  grid-row: 1 / 5;
}
.col7 > div:nth-child(2) {
  grid-row: 5 / 9;
}

/* Column8 */
.col8 > div:nth-child(1) {
  grid-row: 1 / 5;
}
.col8 > div:nth-child(2) {
  grid-row: 5 / 9;
}
.col8 > div:nth-child(3) {
  grid-row: 9 / 13;
}
.col8 > div:nth-child(4) {
  grid-row: 13 / 17;
}

/* Column9 */
.col9 > div:nth-child(1) {
  grid-row: 1;
}
.col9 > div:nth-child(2) {
  grid-row: 2;
}
.col9 > div:nth-child(3) {
  grid-row: 3;
}
.col9 > div:nth-child(4) {
  grid-row: 4;
}
.col9 > div:nth-child(5) {
  grid-row: 5;
}
.col9 > div:nth-child(6) {
  grid-row: 6;
}
.col9 > div:nth-child(7) {
  grid-row: 7;
}
.col9 > div:nth-child(8) {
  grid-row: 8;
}

.loading {
  color: var(--font-colour);
}

.match {
  /* background-color: var(--tertiary-colour);
  border-radius: 12px;
  border: 1px solid var(--primary-colour); */
  animation: matchEntrance 700ms ease-out;
  animation-fill-mode: backwards;
  color: var(--font-colour);
  padding: 3px;

  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: var(--bg-alpha);
  border-radius: 5px;
  border: 1px solid var(--border);
  /* background-color: var(--tertiary-colour);
  color: var(--font-colour);
  border-radius: 12px;
  border: 1px solid var(--primary-colour);
  padding: 3px; */
}

.date {
  font-size: small;
  font-style: italic;
}

.matchTop {
  color: var(--bg-colour);
  border-radius: 5px;
  border: 1px solid var(--border);
  margin-bottom: 3px;
  font-size: smaller;
  font-weight: bold;
}

.matchBot {
  color: var(--bg-colour);
  border-radius: 5px;
  border: 1px solid var(--border);
  font-size: smaller;
  font-weight: bold;
}

.unpop {
  color: var(--font-colour);
}

.lostStyle {
  text-decoration-line: line-through;
  text-decoration-thickness: 20%;
}

@media screen and (orientation: landscape) and (max-width: 1460px) {
  /* .match {
    max-width: 1fr;
  } */

  .header {
    padding: 10px 0;
    font-size: large;
    margin-bottom: 10px;
  }

  .container {
    padding: 0 5px;
  }

  .content {
    column-gap: 5px;
  }

  .date {
    font-size: smaller;
  }
}

@media screen and (max-width: 1460px) and (orientation: portrait) {
  .body {
    height: 100%;
  }

  .container {
    padding: 0 5px;
    background-size: auto;
    background-attachment: scroll;
    background-position: 45% top;
  }

  .header {
    font-size: large;
  }

  .content {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  }

  .col4 {
    grid-row-start: 3;
    grid-column-start: 1;
  }
  .col5 {
    grid-row-start: 3;
    grid-column-start: 2;
  }
  .col6 {
    grid-row-start: 3;
    grid-column-start: 3;
  }

  .date {
    font-size: smaller;
  }

  .match {
    font-size: x-small;
    min-width: 100px;
  }
}
